@import '../../styles/properties/layout.css';
@import '../../styles/properties/breakpoints.css';
@import '../../styles/properties/colors.css';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 90px 5vw *;
  min-height: 100vh;

  @media screen and (--medium-large) {
    padding: calc(var(--header-height-lg) + 150px) 3.4vw *;
  }

  @media screen and (--extra-large) {
    padding: calc(var(--header-height-lg) + 180px) 1.7vw *;

    @supports (display: grid) {
      padding: calc(var(--header-height-lg) + 150px) 3.4vw *;
    }
  }
}
