html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  -webkit-font-feature-settings: 'liga' on;
          font-feature-settings: 'liga' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  margin: 0;
}

/* MiniPax */

@font-face {
  font-family: 'minipax-regular';
  src: url("/assets/fonts/minipax/Minipax-Regular.woff") format('woff'),
       url("/assets/fonts/minipax/Minipax-Regular.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'minipax-medium';
  src: url("/assets/fonts/minipax/Minipax-Medium.woff") format('woff'),
       url("/assets/fonts/minipax/Minipax-Medium.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* Happy Times */

@font-face {
  font-family: 'happy-times';
  src: url("/assets/fonts/happy-times/happy-times-NG_bold_master_web.woff") format('woff'),
       url("/assets/fonts/happy-times/happy-times-NG_bold_master_web.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'happy-times';
  src: url("/assets/fonts/happy-times/happy-times-NG_regular_master_web.woff") format('woff'),
       url("/assets/fonts/happy-times/happy-times-NG_regular_master_web.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'happy-times';
  src: url("/assets/fonts/happy-times/happy-times-NG_italic_master_web.woff") format('woff'),
       url("/assets/fonts/happy-times/happy-times-NG_italic_master_web.woff2") format('woff2');
  font-weight: 400;
  font-style: italic;
}

/* Sporting Grotesque */

@font-face {
  font-family: 'sporting-grotesque';
  src: url("/assets/fonts/sporting-grotesque/Sporting_Grotesque-Bold_web.woff") format('woff'),
       url("/assets/fonts/sporting-grotesque/Sporting_Grotesque-Bold_web.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'sporting-grotesque';
  src: url("/assets/fonts/sporting-grotesque/Sporting_Grotesque-Regular_web.woff") format('woff'),
       url("/assets/fonts/sporting-grotesque/Sporting_Grotesque-Regular_web.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}

:root {
  --minipax: 'minipax-regular', serif;
  --sans: 'sporting-grotesque', sans-serif;
  --serif: 'happy-times', serif;
}

.accordion__resetList--VmM7i {
  padding: 0;
  margin: 0;
  list-style: none;
}
/* Orientation */
/* Breakpoints */
:root {
  --site-padding-sm: 2vw;
  --site-padding-md: 4vw;
  --site-padding-lg: 1.7vw;
  --header-height-sm: 50px;
  --header-height-lg: 60px;
}
:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}
.accordion__wrapper--3N0sS {
  position: relative;
  padding-bottom: 20px
}
.accordion__wrapper--3N0sS:last-child {
    margin-bottom: 0;
    padding-bottom: 30px
  }
@media screen and (min-width: 600px) {
.accordion__wrapper--3N0sS:last-child {
      padding-bottom: 90px
  }
    }
@media screen and (min-width: 768px) {
.accordion__wrapper--3N0sS:last-child {
      padding-bottom: 150px
  }
    }
@media screen and (min-width: 1280px) {
.accordion__wrapper--3N0sS:last-child {
      padding-bottom: 180px
  }
    }
.accordion__wrapperOpen--u4t8s {
  padding-bottom: 90px
}
@media screen and (min-width: 768px) {
.accordion__wrapperOpen--u4t8s {
    padding-bottom: 150px
}
  }
@media screen and (min-width: 1280px) {
.accordion__wrapperOpen--u4t8s {
    padding-bottom: 180px
}
  }
.accordion__active--2jbPx .accordion__title--3yrgO {
    opacity: 0;
  }
.accordion__active--2jbPx li:not(.itemActive) {
    opacity: 0;
  }
.accordion__title--3yrgO {
  position: relative;
  font-family: var(--sans);
  padding: 0;
  display: flex;
  align-items: center;
  font-size: calc(14px + 2 * ((100vw - 420px) / 860));
  line-height: 1;
  color: #000000;
  color: var(--black);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  margin-bottom: 12px;
  padding-left: 4vw;
  padding-left: var(--site-padding-md)
}
@media screen and (min-width: 1280px) {
  .accordion__title--3yrgO {
    font-size: 16px;
  }
}
@media screen and (max-width: 420px) {
  .accordion__title--3yrgO {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
.accordion__title--3yrgO {
    padding-left: 2vw;
    padding-left: var(--site-padding-sm)
}
  }
@media screen and (min-width: 1280px) {
.accordion__title--3yrgO {
    padding-left: 1.7vw;
    padding-left: var(--site-padding-lg)
}
  }
@supports (display: grid) {
.accordion__title--3yrgO {
    padding-left: 0
}
  }
.accordion__title--3yrgO:focus {
    outline: 0;
  }
.accordion__title--3yrgO:after {
    margin-left: 8px;
    margin-bottom: 3px;
    content: '';
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='var(--black)' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M-0.0415726 0.15648L6.27874 8.89596L12.5991 0.15648H-0.0415726Z' fill='black'/%3E %3C/svg%3E");
    background-size: 100% 100%;
    background-position: center center;
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
    width: 10px;
    height: 7px;
  }
.accordion__title--3yrgO.accordion__buttonOpen--1HBeo:after {
      -webkit-transform: rotateZ(0deg);
              transform: rotateZ(0deg);
    }
.accordion__content--2vIYH {
  display: none;
}
.accordion__list--2EMqI {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 5vw;
  width: 100%;
  margin-bottom: calc(4vw * -1);
  margin-bottom: calc(var(--site-padding-md) * -1)
}
@media screen and (min-width: 768px) {
.accordion__list--2EMqI {
    margin-bottom: -50px;
    grid-gap: 3.4vw;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr))
}
  }
@media screen and (min-width: 1280px) {
.accordion__list--2EMqI {
    margin-bottom: -100px
}
  }
@supports (display: grid) {
.accordion__list--2EMqI {
    margin: 0
}
  }
.accordion__list--2EMqI > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1
}
.accordion__buttonOpen--1HBeo {
  position: relative;
}
.accordion__contentOpen--3lrqH {
  position: relative;
  display: flex;
}

/* Orientation */
/* Breakpoints */
:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}
:root {
  --site-padding-sm: 2vw;
  --site-padding-md: 4vw;
  --site-padding-lg: 1.7vw;
  --header-height-sm: 50px;
  --header-height-lg: 60px;
}
.accordionItem__item--yEQME {
  width: 100%;
  float: left;
  padding: 0 4vw;
  padding: 0 var(--site-padding-md);
  margin-bottom: 4vw;
  margin-bottom: var(--site-padding-md)
}
@media screen and (min-width: 768px) {
.accordionItem__item--yEQME {
    padding: 0 2vw;
    padding: 0 var(--site-padding-sm);
    margin-bottom: 1.7vw;
    margin-bottom: var(--site-padding-lg);
    width: 50%;
    margin-bottom: 50px
}
  }
@media screen and (min-width: 1280px) {
.accordionItem__item--yEQME {
    padding: 0 1.7vw;
    padding: 0 var(--site-padding-lg);
    width: 25%;
    margin-bottom: 100px
}
  }
.accordionItem__item--yEQME .lazyloaded {
    opacity: 1;
  }
@supports (display: grid) {
.accordionItem__item--yEQME {
    width: auto
}
  }
@supports (grid-gap: 3.4vw) {
.accordionItem__item--yEQME {
    margin: 0;
    padding: 0
}
  }
.accordionItem__link--2O69O {
  display: block
}
.accordionItem__link--2O69O:focus {
    box-shadow: 0 2vw 4vw rgba(0, 0, 0, 0.2);
    box-shadow: 0 2vw 4vw var(--gray);
    outline: 0;
  }
.accordionItem__link--2O69O:active {
    box-shadow: none;
  }
.accordionItem__content--114Wd {
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: var(--gray);
}
.accordionItem__img--4HD5a {
  display: block;
  width: 100%;
  opacity: 0;
}

/* Orientation */
/* Breakpoints */
:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}
:root {
  --site-padding-sm: 2vw;
  --site-padding-md: 4vw;
  --site-padding-lg: 1.7vw;
  --header-height-sm: 50px;
  --header-height-lg: 60px;
}
.accordionItemMobile__item--FTp0n {
  width: 100%;
  float: left;
  padding: 0 4vw;
  padding: 0 var(--site-padding-md);
  margin-bottom: 4vw;
  margin-bottom: var(--site-padding-md)
}
@media screen and (min-width: 768px) {
.accordionItemMobile__item--FTp0n {
    padding: 0 2vw;
    padding: 0 var(--site-padding-sm);
    margin-bottom: 1.7vw;
    margin-bottom: var(--site-padding-lg);
    width: 50%;
    margin-bottom: 50px
}
  }
@media screen and (min-width: 1280px) {
.accordionItemMobile__item--FTp0n {
    padding: 0 1.7vw;
    padding: 0 var(--site-padding-lg);
    width: 25%;
    margin-bottom: 100px
}
  }
.accordionItemMobile__item--FTp0n .lazyloaded {
    opacity: 1;
  }
@supports (display: grid) {
.accordionItemMobile__item--FTp0n {
    width: auto
}
  }
@supports (grid-gap: 3.4vw) {
.accordionItemMobile__item--FTp0n {
    margin: 0;
    padding: 0
}
  }
.accordionItemMobile__link--31km_ {
  display: block
}
.accordionItemMobile__link--31km_:focus {
    box-shadow: 0 2vw 4vw rgba(0, 0, 0, 0.2);
    box-shadow: 0 2vw 4vw var(--gray);
    outline: 0;
  }
.accordionItemMobile__link--31km_:active {
    box-shadow: none;
  }
.accordionItemMobile__content--3dhm2 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: var(--gray);
}
.accordionItemMobile__img--1WFpY {
  display: block;
  width: 100%;
  opacity: 0;
}

:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}
/* Orientation */
/* Breakpoints */
.header__wrapper--2nDUP {
  z-index: 15;
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  padding: 10px;
  background-color: #ffffff;
  background-color: var(--white)
}
@media screen and (min-width: 768px) {
.header__wrapper--2nDUP {
    padding: 20px
}
  }
.header__wrapper--2nDUP:before {
    content: '';
  }
.header__content--BBdev {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-height-sm)
}
@media screen and (min-width: 768px) {
.header__content--BBdev {
    height: var(--header-height-lg)
}
  }
.header__title--YuWkn,
.header__mobileTitle--1XeOx {
  font-family: var(--serif);
  font-weight: 400;
  display: block;
  font-size: calc(18px + 6 * ((100vw - 420px) / 860));
  color: #000000;
  color: var(--black)
}
@media screen and (min-width: 1280px) {
  .header__title--YuWkn,
.header__mobileTitle--1XeOx {
    font-size: 24px;
  }
}
@media screen and (max-width: 420px) {
  .header__title--YuWkn,
.header__mobileTitle--1XeOx {
    font-size: 18px;
  }
}
.header__title--YuWkn span, .header__mobileTitle--1XeOx span {
    display: block;
    font-weight: 400;
  }
.header__title--YuWkn a, .header__mobileTitle--1XeOx a {
    color: #000000;
    color: var(--black)
  }
.header__title--YuWkn a:hover,
    .header__title--YuWkn a:focus,
    .header__mobileTitle--1XeOx a:hover,
    .header__mobileTitle--1XeOx a:focus {
      text-decoration: none;
    }
.header__active--3qNiZ {
  background: 0;
  mix-blend-mode: difference;
  pointer-events: none
}
.header__active--3qNiZ .header__title--YuWkn {
    color: white;
  }

.footer__resetList--1vdFF {
  padding: 0;
  margin: 0;
  list-style: none;
}
/* Orientation */
/* Breakpoints */
:root {
  --site-padding-sm: 2vw;
  --site-padding-md: 4vw;
  --site-padding-lg: 1.7vw;
  --header-height-sm: 50px;
  --header-height-lg: 60px;
}
:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}
.footer__wrapper--1TdPF {
  position: relative;
  padding: 0 4vw 30px;
  padding: 0 var(--site-padding-md) 30px;
  margin-top: auto
}
@media screen and (min-width: 768px) {
.footer__wrapper--1TdPF {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0;
    padding-left: var(--site-padding-sm);
    padding-right: var(--site-padding-sm);
    padding-top: 0
}
  }
@media screen and (min-width: 1280px) {
.footer__wrapper--1TdPF {
    padding: 0 calc(1.7vw) 48px;
    padding: 0 calc(var(--site-padding-lg)) 48px
}
  }
@media screen and (min-width: 1400px) {
.footer__wrapper--1TdPF {
    padding-bottom: 60px
}
  }
@supports (display: grid) {
.footer__wrapper--1TdPF {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
}
  }
.footer__content--1I62- {
  position: relative;
  line-height: 1
}
@media screen and (min-width: 600px) {
.footer__content--1I62- {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}
  }
.footer__active--k03iN {
  opacity: 0;
}
.footer__list--2iqsE {
  list-style: none;

  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 2px
}
@media screen and (min-width: 600px) {
.footer__list--2iqsE {
    width: auto;
    margin: 0 0 0 auto
}
  }
.footer__item--2ooJn {
  display: inline-block;
  padding-left: 15px
}
@media screen and (min-width: 600px) {
.footer__item--2ooJn {
    padding-left: 20px
}
  }
.footer__item--2ooJn:first-child {
    padding-left: 0;
  }
.footer__copyright--16vkR,
.footer__item--2ooJn {
  font-family: var(--sans);
  font-size: calc(10px + 2 * ((100vw - 420px) / 860))
}
@media screen and (min-width: 1280px) {
  .footer__copyright--16vkR,
.footer__item--2ooJn {
    font-size: 12px;
  }
}
@media screen and (max-width: 420px) {
  .footer__copyright--16vkR,
.footer__item--2ooJn {
    font-size: 10px;
  }
}
.footer__copyright--16vkR a, .footer__item--2ooJn a {
    color: #000000;
    color: var(--black);
    text-decoration: underline
  }
.footer__copyright--16vkR a:hover,
    .footer__copyright--16vkR a:focus,
    .footer__item--2ooJn a:hover,
    .footer__item--2ooJn a:focus {
      text-decoration: none;
    }

.photoGradient__wrapper--34FnF {
  position: relative;
}

.photoGradient__content--1XwCh {
  position: relative;
}

.photoGradient__move--15Xm5 {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  position: relative;
  width: 100%;
  height: 100%;
}

.photoGradient__bg--2vSO_ {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.photoGradient__gradient--2TFiv {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover
}

.photoGradient__gradient--2TFiv:after {
    content: '';
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(100px);
            backdrop-filter: blur(100px);
    z-index: 10;
  }

.photoGradient__data--2EPgM {
  font-family: var(--sans);
  font-weight: 700;
  font-size: 60px;
  mix-blend-mode: difference;
  color: white;
  opacity: 0;
}

.photoGradient__colorList--d2Vp_ {
  bottom: 0;
  left: 0;
  position: absolute;
  font-size: 30px;
  list-style: none;
}

.photoGradient__loading--p2f-e {
  font-family: var(--sans);
  top: 50%;
  left: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 14px;
  display: none;
}

.photoGradient__isLoading--35uDb {
  display: block;
}

:root {
  --site-padding-sm: 2vw;
  --site-padding-md: 4vw;
  --site-padding-lg: 1.7vw;
  --header-height-sm: 50px;
  --header-height-lg: 60px;
}
/* Orientation */
/* Breakpoints */
:root {
  --black: #000000;
  --gray: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --pink: #ffe8e5;
  --future-green: #05ff00;
}
.home__wrapper--5Hh3G {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-color: var(--white);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 90px;
  min-height: 100vh
}
@media screen and (min-width: 768px) {
.home__wrapper--5Hh3G {
    padding-left: 3.4vw;
    padding-right: 3.4vw;
    padding-top: calc(60px + 150px);
    padding-left: 3.4vw;
    padding-right: 3.4vw;
    padding-top: calc(var(--header-height-lg) + 150px)
}
  }
@media screen and (min-width: 1280px) {
.home__wrapper--5Hh3G {
    padding-left: 1.7vw;
    padding-right: 1.7vw;
    padding-top: calc(60px + 180px);
    padding-left: 1.7vw;
    padding-right: 1.7vw;
    padding-top: calc(var(--header-height-lg) + 180px)
}

    @supports (display: grid) {
.home__wrapper--5Hh3G {
      padding-left: 3.4vw;
      padding-right: 3.4vw;
      padding-top: calc(60px + 150px);
      padding-left: 3.4vw;
      padding-right: 3.4vw;
      padding-top: calc(var(--header-height-lg) + 150px)
}
    }
  }

.noMatch__wrapper--3g4TC {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 90px;
  min-height: 100vh;
}

.noMatch__text--3JsR3 {
  font-family: var(--sans);
  top: 50%;
  left: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 14px
}

.noMatch__text--3JsR3 a {
    color: var(--black)
  }

.noMatch__text--3JsR3 a:hover,
    .noMatch__text--3JsR3 a:focus {
      text-decoration: none;
    }

.photo__wrapper--3kGaw {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  height: 100vh;
}

.photo__link--aRE3w {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--photo-primary-color)
}

.photo__link--aRE3w:hover {
    background-color: var(--photo-secondary-color);
  }

.photo__link--aRE3w:focus {
    box-shadow: inset 0 0 50px var(--photo-secondary-color);
  }

.photo__link--aRE3w:active {
    box-shadow: none;
  }

.photo__linkText--1V703 {
  display: none;
}

.photo__list--2Q2EX {
  margin: 0;
}

.photo__bg--3yw-_ {
  display: block;
  z-index: 1;
}

.photo__item--3K_jR {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: none;
}

.photo__container--3E-an {
  width: 100vw;
  height: 100vh;
  background-color: gray;
  justify-content: center;
  align-items: center;
  background-color: gray;
  justify-content: center;
  align-items: center;
  display: flex;
}

.photo__button--2NbHG {
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  padding: 0;
  border: 0
}

.photo__button--2NbHG:focus {
    box-shadow: 0 2vw 4vw var(--gray);
    outline: 0;
  }

.photo__button--2NbHG:active {
    box-shadow: none;
  }

.photo__img--1kjaa {
  position: relative;
  max-width: 100%;
  z-index: 10;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}

.photo__active--25V0v {
  display: block;
}

.photo__isMobile--3NYFm {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100)
}

.photo__isMobile--3NYFm .photo__container--3E-an,
  .photo__isMobile--3NYFm .photo__item--3K_jR {
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
  }

.photo__isMobile--3NYFm .photo__img--1kjaa {
    height: calc(1vh * 70);
    height: calc(var(--vh, 1vh) * 70);
  }

