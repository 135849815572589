@import '../../styles/compositions/global.css';
@import '../../styles/properties/breakpoints.css';
@import '../../styles/properties/layout.css';
@import '../../styles/properties/colors.css';

.wrapper {
  position: relative;
  padding: 0 var(--site-padding-md) 30px;
  margin-top: auto;

  @media screen and (--medium-large) {
    padding: 0 var(--site-padding-sm) *;
  }

  @media screen and (--extra-large) {
    padding: 0 calc(var(--site-padding-lg)) 48px;
  }

  @media screen and (--wide) {
    padding-bottom: 60px;
  }

  @supports (display: grid) {
    padding: 0 0 *;
  }
}

.content {
  position: relative;
  line-height: 1;

  @media screen and (--medium) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.active {
  opacity: 0;
}

.list {
  list-style: none;

  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 2px;

  @media screen and (--medium) {
    width: auto;
    margin: 0 0 0 auto;
  }
}

.item {
  display: inline-block;
  padding-left: 15px;

  @media screen and (--medium) {
    padding-left: 20px;
  }

  &:first-child {
    padding-left: 0;
  }
}

.copyright,
.item {
  font-family: var(--sans);
  font-size: responsive 10px 12px;
  font-range: 420px 1280px;

  & a {
    color: var(--black);
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
