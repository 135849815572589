@import '../../styles/compositions/global.css';
@import '../../styles/properties/breakpoints.css';
@import '../../styles/properties/layout.css';
@import '../../styles/properties/colors.css';

.wrapper {
  position: relative;
  padding-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 30px;

    @media screen and (--medium) {
      padding-bottom: 90px;
    }

    @media screen and (--medium-large) {
      padding-bottom: 150px;
    }

    @media screen and (--extra-large) {
      padding-bottom: 180px;
    }
  }
}

.wrapperOpen {
  padding-bottom: 90px;

  @media screen and (--medium-large) {
    padding-bottom: 150px;
  }

  @media screen and (--extra-large) {
    padding-bottom: 180px;
  }
}

.active {
  & .title {
    opacity: 0;
  }

  & li:not(:global(.itemActive)) {
    opacity: 0;
  }
}

.title {
  position: relative;
  font-family: var(--sans);
  padding: 0;
  display: flex;
  align-items: center;
  font-size: responsive 14px 16px;
  font-range: 420px 1280px;
  line-height: 1;
  color: var(--black);
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  margin-bottom: 12px;
  padding-left: var(--site-padding-md);

  @media screen and (--medium-large) {
    padding-left: var(--site-padding-sm);
  }

  @media screen and (--extra-large) {
    padding-left: var(--site-padding-lg);
  }

  @supports (display: grid) {
    padding-left: 0;
  }

  &:focus {
    outline: 0;
  }

  &:after {
    margin-left: 8px;
    margin-bottom: 3px;
    content: '';
    background: svg-load('arrow-3.svg', fill=var(--black));
    background-size: 100% 100%;
    background-position: center center;
    transform: rotateZ(-90deg);
    size: 10px 7px;
  }

  &.buttonOpen {
    &:after {
      transform: rotateZ(0deg);
    }
  }
}

.content {
  display: none;
}

.list {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 5vw;
  width: 100%;
  composes: resetList;
  margin-bottom: calc(var(--site-padding-md) * -1);

  @media screen and (--medium-large) {
    margin-bottom: -50px;
    grid-gap: 3.4vw;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  @media screen and (--extra-large) {
    margin-bottom: -100px;
  }

  @supports (display: grid) {
    margin: 0;
  }
}

.buttonOpen {
  position: relative;
}

.contentOpen {
  position: relative;
  display: flex;
}
