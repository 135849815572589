.wrapper {
  position: relative;
}

.content {
  position: relative;
}

.move {
  transform: translateZ(0);
  position: relative;
  size: 100%;
}

.bg {
  position: fixed 0 0 0 0;
  size: 100%;
  overflow: hidden;
}

.gradient {
  position: fixed 0 0 0 0;
  size: 100%;
  background-size: cover;

  &:after {
    content: '';
    display: block;
    position: fixed 0 0 0 0;
    size: 100%;
    backdrop-filter: blur(100px);
    z-index: 10;
  }
}

.data {
  font-family: var(--sans);
  font-weight: bold;
  font-size: 60px;
  mix-blend-mode: difference;
  color: white;
  opacity: 0;
}

.colorList {
  position: absolute * * 0 0;
  font-size: 30px;
  list-style: none;
}

.loading {
  font-family: var(--sans);
  position: fixed 50% * * 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  display: none;
}

.isLoading {
  display: block;
}
