@import '../../styles/properties/breakpoints.css';
@import '../../styles/properties/colors.css';
@import '../../styles/properties/layout.css';

.item {
  width: 100%;
  float: left;
  padding: 0 var(--site-padding-md);
  margin-bottom: var(--site-padding-md);

  @media screen and (--medium-large) {
    padding: 0 var(--site-padding-sm);
    margin-bottom: var(--site-padding-lg);
    width: 50%;
    margin-bottom: 50px;
  }

  @media screen and (--extra-large) {
    padding: 0 var(--site-padding-lg);
    width: 25%;
    margin-bottom: 100px;
  }

  & :global(.lazyloaded) {
    opacity: 1;
  }

  @supports (display: grid) {
    width: auto;
  }

  @supports (grid-gap: 3.4vw) {
    margin: 0;
    padding: 0;
  }
}

.link {
  display: block;

  &:focus {
    box-shadow: 0 2vw 4vw var(--gray);
    outline: 0;
  }

  &:active {
    box-shadow: none;
  }
}

.content {
  position: relative;
  background-color: var(--gray);
}

.img {
  display: block;
  width: 100%;
  opacity: 0;
}
