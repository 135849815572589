@import '../../styles/properties/colors.css';
@import '../../styles/properties/breakpoints.css';

.wrapper {
  z-index: 15;
  position: fixed 0 0 * 0;
  padding: 10px;
  background-color: var(--white);

  @media screen and (--medium-large) {
    padding: 20px;
  }

  &:before {
    content: '';
  }
}

.content {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-height-sm);

  @media screen and (--medium-large) {
    height: var(--header-height-lg);
  }
}

.title,
.mobileTitle {
  font-family: var(--serif);
  font-weight: normal;
  display: block;
  font-size: responsive 18px 24px;
  font-range: 420px 1280px;
  color: var(--black);

  & span {
    display: block;
    font-weight: normal;
  }

  & a {
    color: var(--black);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.active {
  background: 0;
  mix-blend-mode: difference;
  pointer-events: none;

  & .title {
    color: white;
  }
}
