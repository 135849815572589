/* MiniPax */

@font-face {
  font-family: 'minipax-regular';
  font-path: '/assets/fonts/minipax/Minipax-Regular';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'minipax-medium';
  font-path: '/assets/fonts/minipax/Minipax-Medium';
  font-weight: normal;
  font-style: normal;
}

/* Happy Times */

@font-face {
  font-family: 'happy-times';
  font-path: '/assets/fonts/happy-times/happy-times-NG_bold_master_web';
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'happy-times';
  font-path: '/assets/fonts/happy-times/happy-times-NG_regular_master_web';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'happy-times';
  font-path: '/assets/fonts/happy-times/happy-times-NG_italic_master_web';
  font-weight: normal;
  font-style: italic;
}

/* Sporting Grotesque */

@font-face {
  font-family: 'sporting-grotesque';
  font-path: '/assets/fonts/sporting-grotesque/Sporting_Grotesque-Bold_web';
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'sporting-grotesque';
  font-path: '/assets/fonts/sporting-grotesque/Sporting_Grotesque-Regular_web';
  font-weight: normal;
  font-style: normal;
}
