.wrapper {
  position: absolute 0 0 0 0;
  height: 100vh;
}

.link {
  position: absolute 0 0 0 0;
  size: 100%;
  z-index: 1;
  background-color: var(--photo-primary-color);

  &:hover {
    background-color: var(--photo-secondary-color);
  }

  &:focus {
    box-shadow: inset 0 0 50px var(--photo-secondary-color);
  }

  &:active {
    box-shadow: none;
  }
}

.linkText {
  display: none;
}

.list {
  margin: 0;
}

.bg {
  display: block;
  z-index: 1;
}

.item {
  position: absolute 0 0 0 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: none;
}

.container {
  width: 100vw;
  height: 100vh;
  background-color: gray;
  justify-content: center;
  align-items: center;
  background-color: gray;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button {
  background: transparent;
  appearance: none;
  outline: 0;
  padding: 0;
  border: 0;

  &:focus {
    box-shadow: 0 2vw 4vw var(--gray);
    outline: 0;
  }

  &:active {
    box-shadow: none;
  }
}

.img {
  position: relative;
  max-width: 100%;
  z-index: 10;
  object-fit: contain;
  display: block;
}

.active {
  display: block;
}

.isMobile {
  height: calc(var(--vh, 1vh) * 100);
  & .container,
  & .item {
    height: calc(var(--vh, 1vh) * 100);
  }

  & .img {
    height: calc(var(--vh, 1vh) * 70);
  }
}
