.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 90px 5vw *;
  min-height: 100vh;
}

.text {
  font-family: var(--sans);
  position: fixed 50% * * 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;

  & a {
    color: var(--black);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
